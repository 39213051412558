import React, { Component } from "react";
//import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Loader from "../../loader";
import {
    TextField,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Button,
    Box,
    Tooltip,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import customeCSS from "./PlanSelection.css";
import configuration from "../../../configurations";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
// import {Panel} from 'react-bootstrap';
import axios from "axios";
import CommonTable from "../../CommonScreens/commonTable";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import i18n from "../../../i18next";
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from "@material-ui/pickers";
import planConfig from "../../../planConfig";
import "./Add-ons/addOnsScreen.css";
import WellLifePlus from "../../../Assets/pdf/WellLife+_Program_Grid.pdf";
import {
    getPlanName,
    isAdvantage,
    isElite,
    isEmployer,
    isPureCat,
    isWellLife,
} from "./CheckClientIds";

const ProceedButton = withStyles(
    customStyle.proceedNetwellBtn,
)(Button);

const CustomeButton = withStyles(
    customStyle.viewNetwellBtn,
)(Button);

const ViewButton = withStyles(
    customStyle.viewNetwellBtn,
)(Button);

const CssTextField = withStyles((theme) => ({
    root: {
        "& .MuiInput-root": {
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "2px solid #533278",
            },

            "&.MuiInput-underline.Mui-focused:after": {
                borderBottom: "2px solid #533278",
            },
        },
    },
}))(TextField);

const styles = (theme) => ({
    table: {
        minWidth: 400,
    },
});

class PlanSelection extends Component {
    constructor(props) {
        super(props);
        const today = new Date().getDate() + 5;
        const tomorrow = new Date(today);
        if (
            sessionStorage.getItem("CLIENT_ID") === "6548" ||
            sessionStorage.getItem("CLIENT_ID") === "4367" ||
            sessionStorage.getItem("CLIENT_ID") === "5540" ||
            sessionStorage.getItem("CLIENT_ID") === "4376" ||
            sessionStorage.getItem("CLIENT_ID") === "5541" ||
            sessionStorage.getItem("CLIENT_ID") === "4377"
        ) {
            if (new Date() < new Date("05/01/2021")) {
                tomorrow.setDate(1);
                tomorrow.setMonth(4);
            } else if (new Date().getDate() === 1 || new Date().getDate() > 1) {
                tomorrow.setDate(1);
                tomorrow.setMonth(today.getMonth() + 1);
            }
        } else {
            tomorrow.setDate(tomorrow.getDate() + 5);
        }

        this.state = {
            loaderShow: false,
            sharingPlan: "",
            plansList: [],
            modalShow: false,
            selectedPlanCode: "",
            planFamilyDetails: [],
            otherQuote: [],
            todayPayment: [],
            recurringPayment: [],
            totalCost: "",
            headerData: [],
            tooltipData: [],
            checkedB: true,
            targetDate: new Date(tomorrow),
            dateErr: false,
            birthDtFocus: false,
            todayDateValid: false,
            birthDt: false,
            checkedPlan: false,
            Checked: "",
            clientId: sessionStorage.getItem("CLIENT_ID"),
            plansListNew: [],
            text: "",
            count: 0,
            ACSMValue: true,
            ACSMPlanAll: ["UHS1", "UHS2", "UHS3", "UHS4", "UHS5", "UHS6"],
            ACSMPlan: ["UHS2", "UHS4", "UHS6"],
            msgModal: false,
            errMsg: "",
            easyShareModal: false,
            empEffectiveDate: null,
            iframeURL: "",
            reEnroll: this.props.isChangeProgram || this.props.isHouseholdUpdate || this.props.reEnrollBySC,
            nextRecurringDate: "",
            nextEffectiveDate: "",
            tarDate: "",
            minDate: null,
            myDate: null,
            reEnrollDate: null,
            zeroAppFee: false,
            programType: null,
            fromAgent: sessionStorage.getItem("STATE_PARAM")
                ? JSON.parse(sessionStorage.getItem("STATE_PARAM")).isAgent
                : false,
            reEnrollBySC: this.props.reEnrollBySC ? true : false,
        };
    }

    componentDidMount() {
        let subId = JSON.parse(localStorage.getItem("CurrentLoginUser")).id;
        let data = { clientId: sessionStorage.getItem("CLIENT_ID") };
        axios
            .post(configuration.baseUrl + "/enrollment/getClient", data)
            .then((response) => {
                if (response.data.response) {
                    this.setState({ programType: response.data.response.programType });
                }
            });
        axios
            .get(
                configuration.baseUrl +
                "/enrollment/saveCompletionStatus/" +
                subId +
                "/" +
                5,
            )
            .then((response) => {
                if (response && response.data.code === 200)
                    console.log(response.data.code);
            })
            .catch((error) => console.log(error));

        window.scrollTo(0, 0);
        sessionStorage.setItem("current_screen", "5");
        window.addEventListener("message", this.handlePlanGridSelection, false);
        this.setState({
            loaderShow: true,
            fromAgent: sessionStorage.getItem("STATE_PARAM")
                ? JSON.parse(sessionStorage.getItem("STATE_PARAM")).isAgent
                : false,
        });
        fetch(
            configuration.baseUrl +
            "/plan/getPlanListForUser/" +
            sessionStorage.getItem("CLIENT_ID") +
            "/" +
            this.props.subId,
        )
            .then((response) => response.json())
            .then((response) => {
                let planlist = [];

                if (!isEmployer(sessionStorage.getItem("CLIENT_ID").toString())) {
                    let newArr = response.response;
                    for (let i = newArr.length - 1; i >= 0; i--) {
                        planlist.push(JSON.parse(JSON.stringify(response.response[i])));
                    }
                } else {
                    planlist = response.response;
                }

                fetch(`${configuration.baseUrl}/plan/getMemberPlan/${this.props.subId}/Core`)
                    .then((selectedPlan) => selectedPlan.json())
                    .then((selectedPlan) => {
                        let sharingPlan = "";
                        let selectedPlanCode = "";
                        if (selectedPlan.response && selectedPlan.response.appFeeWaiver)
                            this.setState({ zeroAppFee: selectedPlan.response.appFeeWaiver });
                        if (!selectedPlan.response) {
                            sharingPlan = planlist[planlist.length - 1].id;
                            selectedPlanCode = planlist[planlist.length - 1].planCode;
                        } else {
                            let index = planlist.findIndex(
                                (obj) => obj.id === selectedPlan.response.planId,
                            );
                            if (index > -1) {
                                sharingPlan = selectedPlan.response.planId;
                                selectedPlanCode = selectedPlan.response.planCode;
                            } else {
                                sharingPlan = planlist[planlist.length - 1].id;
                                selectedPlanCode = planlist[planlist.length - 1].planCode;
                            }
                        }
                        let empEffectiveDate =
                            selectedPlan.response && selectedPlan.response.effectiveDate
                                ? selectedPlan.response.effectiveDate
                                : null;
                        this.setState({
                            empEffectiveDate: empEffectiveDate,
                        });

                        let ACSM =
                            sessionStorage.getItem("CLIENT_ID") === "6548" ||
                                sessionStorage.getItem("CLIENT_ID") === "4350" ||
                                sessionStorage.getItem("CLIENT_ID") === "4367" ||
                                sessionStorage.getItem("CLIENT_ID") === "5540" ||
                                sessionStorage.getItem("CLIENT_ID") === "4376" ||
                                sessionStorage.getItem("CLIENT_ID") === "5541" ||
                                sessionStorage.getItem("CLIENT_ID") === "4377" ||
                                sessionStorage.getItem("CLIENT_ID") === "5558" ||
                                sessionStorage.getItem("CLIENT_ID") === "4386"
                                ? false
                                : selectedPlan.response
                                    ? selectedPlan.response.acsm
                                    : true;
                        fetch(
                            configuration.baseUrl +
                            "/plan/quoteByPlan/" +
                            this.props.subId +
                            "/" +
                            sharingPlan +
                            "/" +
                            ACSM +
                            "/" +
                            "Netwell",
                        )
                            .then((resNew) => resNew.json())
                            .then((resNew) => {
                                if (resNew && resNew.code === 200) {
                                    let res = resNew.response;

                                    let amt = res.todayPayment[0].amount.split("$");
                                    let surcharge =
                                        res.quote[res.quote.length - 1].surcharge.split("$");
                                    let val;
                                    if (surcharge[1])
                                        val = parseFloat(amt[1]) + parseFloat(surcharge[1]);
                                    else
                                        val = parseFloat(
                                            res.quote[res.quote.length - 1].amount.split("$")[1],
                                        );
                                    this.setState({ oldPlan: res.header[1] })
                                    const today = new Date();
                                    const tomorrow = new Date(today);
                                    if (
                                        sessionStorage.getItem("CLIENT_ID") === "6548" ||
                                        sessionStorage.getItem("CLIENT_ID") === "4367" ||
                                        sessionStorage.getItem("CLIENT_ID") === "5540" ||
                                        sessionStorage.getItem("CLIENT_ID") === "4376" ||
                                        sessionStorage.getItem("CLIENT_ID") === "5541" ||
                                        sessionStorage.getItem("CLIENT_ID") === "4377"
                                    ) {
                                        if (new Date() < new Date("05/01/2021")) {
                                            if (new Date() < new Date(empEffectiveDate)) {
                                                let efectiveDate = new Date(empEffectiveDate);
                                                let effectiveDay = new Date(empEffectiveDate).getDate();
                                                if (effectiveDay === 1 || effectiveDay > 1) {
                                                    tomorrow.setDate(1);
                                                    tomorrow.setMonth(
                                                        new Date(efectiveDate).getMonth() + 1,
                                                    );
                                                }
                                            } else {
                                                tomorrow.setDate(1);
                                                tomorrow.setMonth(4);
                                            }
                                        } else if (
                                            new Date().getDate() === 1 ||
                                            new Date().getDate() > 1
                                        ) {
                                            if (new Date() < new Date(empEffectiveDate)) {
                                                let efectiveDate = new Date(empEffectiveDate);
                                                let effectiveDay = new Date(empEffectiveDate).getDate();
                                                if (effectiveDay === 1 || effectiveDay > 1) {
                                                    tomorrow.setDate(1);
                                                    tomorrow.setMonth(
                                                        new Date(efectiveDate).getMonth() + 1,
                                                    );
                                                }
                                            } else {
                                                tomorrow.setDate(1);
                                                tomorrow.setMonth(today.getMonth() + 1);
                                            }
                                        }
                                    } else {
                                        console.log("------ date");
                                        let efectiveDate = new Date();
                                        let myNwDate = new Date();
                                        // let efectiveDate = empEffectiveDate ? new Date(empEffectiveDate) : new Date();
                                        if (
                                            isEmployer(sessionStorage.getItem("CLIENT_ID").toString())
                                        ) {
                                            let currentDate = today.setDate(today.getDate() + 45);
                                            currentDate = new Date(currentDate);
                                            let newCurrentDate =
                                                moment(currentDate).format("MM") +
                                                "/" +
                                                moment(currentDate).format("DD") +
                                                "/" +
                                                moment(currentDate).format("YYYY");
                                            let currEffectiveDate = new Date(empEffectiveDate);
                                            let newCurrEffectiveDate =
                                                moment(currEffectiveDate).format("MM") +
                                                "/" +
                                                moment(currEffectiveDate).format("DD") +
                                                "/" +
                                                moment(currEffectiveDate).format("YYYY");

                                            if (
                                                new Date(newCurrentDate) <
                                                new Date(newCurrEffectiveDate)
                                            ) {
                                                let myCurrEffDate = null;
                                                if (selectedPlan.response.targetDate !== null) {
                                                    if (
                                                        new Date(
                                                            moment(selectedPlan.response.targetDate).format(
                                                                "MM-DD-YYYY",
                                                            ),
                                                        ) >
                                                        new Date(
                                                            moment(currEffectiveDate).format("MM-DD-YYYY"),
                                                        )
                                                    ) {
                                                        myCurrEffDate = moment
                                                            .utc(selectedPlan.response.targetDate)
                                                            .format("MM-DD-YYYY");
                                                    } else
                                                        myCurrEffDate = moment
                                                            .utc(currEffectiveDate)
                                                            .format("MM-DD-YYYY");
                                                } else
                                                    myCurrEffDate = moment
                                                        .utc(currEffectiveDate)
                                                        .format("MM-DD-YYYY");
                                                this.setState({
                                                    minDate: currEffectiveDate,
                                                    myDate: myCurrEffDate,
                                                    targetDate: myCurrEffDate,
                                                });
                                            } else {
                                                if (
                                                    currentDate.getDate() === 1 ||
                                                    currentDate.getDate() <= 15
                                                ) {
                                                    currentDate.setDate(15);
                                                    currentDate.setMonth(today.getMonth());
                                                } else if (currentDate.getDate() > 15) {
                                                    currentDate.setDate(1);
                                                    currentDate.setMonth(today.getMonth() + 1);
                                                }
                                                let myCurrDate = null;
                                                if (selectedPlan.response.targetDate !== null) {
                                                    if (
                                                        new Date(
                                                            moment(selectedPlan.response.targetDate).format(
                                                                "MM-DD-YYYY",
                                                            ),
                                                        ) >
                                                        new Date(moment(currentDate).format("MM-DD-YYYY"))
                                                    ) {
                                                        myCurrDate = moment
                                                            .utc(selectedPlan.response.targetDate)
                                                            .format("MM-DD-YYYY");
                                                    } else
                                                        myCurrDate = moment
                                                            .utc(currentDate)
                                                            .format("MM-DD-YYYY");
                                                } else
                                                    myCurrDate = moment
                                                        .utc(currentDate)
                                                        .format("MM-DD-YYYY");
                                                // let myCurrDate = selectedPlan.response !== null ? selectedPlan.response.targetDate : currentDate
                                                this.setState({
                                                    minDate: currentDate,
                                                    myDate: myCurrDate,
                                                    targetDate: myCurrDate,
                                                });
                                            }
                                        }
                                        if (
                                            !isEmployer(
                                                sessionStorage.getItem("CLIENT_ID").toString(),
                                            )
                                        ) {
                                            let myNDate = new Date();
                                            myNDate.setDate(myNDate.getDate() + 5);
                                            let newDate = null;

                                            if (selectedPlan.response !== null) {
                                                if (
                                                    new Date(
                                                        moment(selectedPlan.response.targetDate).format(
                                                            "MM-DD-YYYY",
                                                        ),
                                                    ) > new Date(moment(myNDate).format("MM-DD-YYYY"))
                                                ) {
                                                    newDate = moment
                                                        .utc(selectedPlan.response.targetDate)
                                                        .format("MM-DD-YYYY");
                                                } else
                                                    newDate = moment.utc(myNDate).format("MM-DD-YYYY");
                                            } else newDate = moment.utc(myNDate).format("MM-DD-YYYY");

                                            this.setState({
                                                minDate: new Date(myNDate),
                                                myDate: newDate,
                                                targetDate: newDate,
                                            });
                                        }
                                    }

                                    let targetDate =
                                        selectedPlan.response && selectedPlan.response.targetDate
                                            ? moment(selectedPlan.response.targetDate).format(
                                                "MM-DD-YYYY",
                                            )
                                            : moment(new Date(tomorrow)).format("MM-DD-YYYY");
                                    this.setState({
                                        planFamilyDetails: res.quote,
                                        headerData: res.header,
                                        plansList: planlist,
                                        loaderShow: false,
                                        otherQuote: res.selectPlan,
                                        text: res.text.text,
                                        recurringPayment: res.recurringPayment,
                                        todayPayment: res.todayPayment,
                                        totalCost: Number(val).toFixed(2),
                                        sharingPlan: sharingPlan,
                                        selectedPlanCode: selectedPlanCode,
                                        tooltipData: res.surchargeTooltip,
                                        //   targetDate: targetDate,
                                        tarDate: targetDate,
                                        Checked: sharingPlan,
                                        checkedPlan: true,
                                        reEnrollDate: targetDate,
                                    });
                                } else {
                                    this.setState({
                                        msgModal: true,
                                        errMsg: resNew.message,
                                        loaderShow: false,
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
        if (this.state.reEnroll) {
            this.setState({ loaderShow: true });
            var memberID = JSON.parse(sessionStorage.getItem("STATE_PARAM")).memberId;
            axios
                .get(
                    configuration.transactionURL +
                    "/adminportal/getRecurringDate/" +
                    memberID,
                )
                .then((respone) => {
                    let recurringDate = moment(respone.data.response.recurringDate)
                        .utc()
                        .format("MMMM DD, YYYY");
                    let nextEffectiveDate = moment(respone.data.response.recurringDate)
                        .add(respone.data.response.draftDay, "days").utc()
                        .format("MMMM DD, YYYY");
                    this.setState({
                        nextRecurringDate: recurringDate,
                        loaderShow: false,
                        nextEffectiveDate
                    });
                });
        }
    }

    handlePlanGridSelection = (event) => {
        let planId = JSON.parse(event.data);
        this.setState({
            Checked: planId.selected_val,
        });
    };

    viewPlanHideModal = (event, id) => {
        this.setState({
            loaderShow: true,
        });
        let planCode = this.state.plansList.find(
            (e) => e.id.toString() === id.toString(),
        );
        fetch(
            configuration.baseUrl +
            "/plan/quoteByPlan/" +
            this.props.subId +
            "/" +
            planCode.id +
            "/" +
            this.state.ACSMValue +
            "/" +
            "Netwell",
        )
            .then((responseNew) => responseNew.json())
            .then((responseNew) => {
                if (responseNew && responseNew.code === 200) {
                    let response = responseNew.response;
                    let amt = response.todayPayment[0].amount.split("$");
                    let surcharge =
                        response.quote[response.quote.length - 1].surcharge.split("$");
                    let val;
                    if (surcharge[1]) val = parseFloat(amt[1]) + parseFloat(surcharge[1]);
                    else
                        val = parseFloat(
                            response.quote[response.quote.length - 1].amount.split("$")[1],
                        );

                    this.setState({
                        planFamilyDetails: response.quote,
                        headerData: response.header,
                        loaderShow: false,
                        otherQuote: response.selectPlan,
                        totalCost: this.state.checkedPlan ? val.toFixed(2) : "-",
                        sharingPlan: planCode.id,
                        selectedPlanCode: planCode.planCode,
                        tooltipData: response.surchargeTooltip,
                        Checked: planCode.id,
                        checkedPlan: true,
                        modalShow: false,
                    });
                } else {
                    this.setState({
                        msgModal: true,
                        errMsg: responseNew.message,
                        loaderShow: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    hideModal = (event, id) => {
        this.setState({
            modalShow: false,
        });
    };

    showPlansModal = (event) => {
        let plan = JSON.parse(JSON.stringify(this.state.plansList));
        let planIndex = plan.findIndex((obj) => obj.planCode === "UHS SmartShare");
        let newPlan = this.state.plansList;
        let selectedPlanId = "";
        if (planIndex > -1) {
            selectedPlanId = plan[planIndex].id;
            plan.splice(planIndex, 1);
        }
        let obj = planConfig.find(
            (obj) => obj.CLIENT_ID.toString() === this.state.clientId.toString(),
        );

        if (selectedPlanId === this.state.sharingPlan) {
            this.setState({
                modalShow: false,
                Checked: this.state.sharingPlan,
                plansListNew: plan,
                iframeURL: obj.iframeURL,
            });
        } else {
            this.setState({
                modalShow: true,
                Checked: this.state.sharingPlan,
                plansListNew: plan,
                loaderShow: true,
                iframeURL: obj.iframeURL,
            });
        }
    };

    submitPlan = () => {
        this.setState({
            loaderShow: true,
        });
        localStorage.setItem("appFeeWaiver", this.state.zeroAppFee);
        let date = moment(this.state.targetDate).format("YYYY-MM-DD");
        let reEnrollDate = moment(this.state.reEnrollDate).format("YYYY-MM-DD");
        let data = {
            subId: this.props.subId,
            planId: this.state.sharingPlan,
            planCode: this.state.selectedPlanCode,
            amount: this.state.totalCost,
            appFeeWaiver: this.state.zeroAppFee,
            targetDate: this.state.reEnroll ? reEnrollDate : date,
            acsm: this.state.ACSMValue,
        };

        axios
            .post(configuration.baseUrl + "/plan/saveMemberPlan", data)
            .then((response) => {
                this.setState({
                    loaderShow: false,
                });
                this.props.onClick();
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getValue = (val, itemValid, parentDetails) => {
        let count = 0;
        let ageValidator = 0;
        if (parentDetails.flag === "SELECT_PLAN") {
            if (parentDetails.label === "Select Sharing Programs") {
                if (itemValid) {
                    this.state.sharingPlan = val;
                } else {
                    this.state.sharingPlan = "";
                }
            }
        }
    };

    isItemChecked(abilityName) {
        return parseInt(this.state.Checked) === parseInt(abilityName)
            ? true
            : false;
    }

    handleDateChange = (date, didMount) => {
        console.log("date---", date);
        this.setState(
            {
                targetDate: date,
                myDate: date,
            },
            () => {
                let panel = document.getElementById("date-picker-dialog");
                panel.addEventListener("onmouseleave", function () {
                    document.getElementById("date-picker-dialog-label").style.paddingTop =
                        "10px";
                });
            },
        );
    };
    backToSelectProgram = () => {
        this.setState({ count: 0 });
    };

    disableWeekends(date, clientId) {
        console.log("datdisableWeekends----", date);
        if (isEmployer(clientId.toString())) {
            if (date.getDate() === 1 || date.getDate() === 15) {
                return false;
            } else {
                return true;
            }
        }
    }

    render() {
        console.log(
            "state---",
            this.state.myDate,
            " - - - -  ",
            this.state.reEnrollDate,
        );
        let classes = this.props;
        let currentScreen = "";

        let myDate =
            moment(this.state.targetDate).format("MM") +
            "/" +
            moment(this.state.targetDate).format("DD") +
            "/" +
            moment(this.state.targetDate).format("YYYY");

        const today = new Date();
        const tomorrow = new Date(today);

        tomorrow.setDate(tomorrow.getDate());
        console.log("tomorrow--", tomorrow);
        let futureTomarow = new Date(today);
        console.log("futureTomarow--", futureTomarow);

        let futureDate = new Date(today);
        if (!isEmployer(sessionStorage.getItem("CLIENT_ID").toString())) {
            if (new Date() < new Date("05/01/2021")) {
                if (new Date() < new Date(this.state.empEffectiveDate)) {
                    let efectiveDate = new Date(this.state.empEffectiveDate);
                    let effectiveDay = new Date(this.state.empEffectiveDate).getDate();
                    if (effectiveDay === 1 || effectiveDay > 1) {
                        tomorrow.setDate(1);
                        tomorrow.setMonth(new Date(efectiveDate).getMonth() + 1);

                        futureTomarow.setDate(1);
                        futureTomarow.setMonth(new Date(efectiveDate).getMonth() + 1);
                    }
                } else {
                    tomorrow.setDate(1);
                    tomorrow.setMonth(4);
                    futureTomarow.setDate(1);
                    futureTomarow.setMonth(4);
                }
            } else if (new Date().getDate() === 1 || new Date().getDate() > 1) {
                if (new Date() < new Date(this.state.empEffectiveDate)) {
                    let efectiveDate = new Date(this.state.empEffectiveDate);
                    let effectiveDay = new Date(this.state.empEffectiveDate).getDate();
                    if (effectiveDay === 1 || effectiveDay > 1) {
                        tomorrow.setDate(1);
                        tomorrow.setMonth(new Date(efectiveDate).getMonth() + 1);
                        futureTomarow.setDate(1);
                        futureTomarow.setMonth(new Date(efectiveDate).getMonth() + 1);
                    }
                } else {
                    tomorrow.setDate(1);
                    tomorrow.setMonth(today.getMonth() + 1);
                    futureTomarow.setDate(1);
                    futureTomarow.setMonth(today.getMonth() + 1);
                }
            }
            futureDate = futureTomarow.setDate(futureTomarow.getDate() + 45);
        } else if (isEmployer(this.state.clientId.toString())) {
            futureDate = new Date(this.state.minDate);
            tomorrow.setDate(tomorrow.getDate() + 1);
            if (futureDate.getDate() === 1) {
                futureDate.setDate(1);
                futureDate = futureDate.setDate(futureDate.getDate() + 45);
            } else {
                futureDate.setDate(15);
                futureDate = futureDate.setDate(futureDate.getDate() + 45);
            }
        }

        if (this.state.count === 0) {
            currentScreen = (
                <Grid container direction="row">
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        style={{ marginBottom: "20px" }}
                        className={
                            this.props.isChangeProgram || this.props.isHouseholdUpdate
                                ? "disabledReenrollPlanSelection"
                                : ""
                        }
                    >

                        <Grid item sm={10} xs={12} style={{ marginTop: "30px" }}>
                            <div style={customStyle.EnrollNew2DateMob}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        key={
                                            this.state.reEnroll
                                                ? this.state.reEnrollDate
                                                : this.state.myDate
                                        }
                                        onBlur={() => this.setState({ birthDtFocus: true })}
                                        onMouseOver={() => this.setState({ birthDt: true })}
                                        onMouseLeave={() => this.setState({ birthDt: false })}
                                        autoComplete="off"
                                        margin="none"
                                        id="date-picker-dialog"
                                        label="Select Program Effective Date"
                                        format="MM/dd/yyyy"
                                        disabled={
                                            this.props.isChangeProgram || this.props.isHouseholdUpdate || this.props.reEnrollBySC
                                        }
                                        error={this.state.dateErr} //&&!this.state.todayDateValid
                                        helperText={this.state.dateErr ? "Enter valid date" : ""} //this.state.todayDateValid?'Date Required':
                                        value={
                                            this.state.reEnroll
                                                ? this.state.reEnrollDate
                                                : this.state.myDate
                                        }
                                        defaultValue={
                                            this.state.reEnroll
                                                ? this.state.reEnrollDate
                                                : this.state.myDate
                                        }
                                        onFocus={(e) => e.target.blur()}
                                        onCopy={this.handlerCopy}
                                        onPaste={this.handlerCopy}
                                        inputProps={{
                                            style: {
                                                fontSize: "18px",
                                                fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                                paddingLeft: "11px",
                                                paddingRight: "10px",
                                                marginTop: "11px",
                                                "&:focus": { outline: "none" },
                                                color: !this.state.birthDt ? "#19191d" : "#533278",
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 12,
                                                color: !this.state.birthDtFocus
                                                    ? "grey"
                                                    : this.state.birthDt
                                                        ? "#533278"
                                                        : "grey",
                                            },
                                        }} //|| !this.state.todayDateValid
                                        onChange={this.handleDateChange.bind(this)}
                                        variant="filled"
                                        // onMouseEnter={this.handleHover}
                                        TextFieldComponent={CssTextField}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        style={{ width: "100%" }}
                                        shouldDisableDate={(e) =>
                                            this.disableWeekends(e, this.state.clientId)
                                        }
                                        minDate={this.state.minDate}
                                        maxDate={new Date(futureDate)}
                                    />
                                    <span id="bd" style={customStyle.EnrollNew2Span}></span>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        return (
            <div>
                {this.state.loaderShow ? <Loader></Loader> : ""}

                {this.props.reEnrollBySC ?
                    <p
                        style={{
                            fontSize: "14px",
                            lineHeight: "16px",
                            textAlign: "justify",
                            fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                        }}
                        dangerouslySetInnerHTML={{ __html: i18n.t("PLAN_SELECTION.PLAN_CHANGE").replace("oldPgm", this.state.oldPlan).replace("pgmName", getPlanName(this.state.sharingPlan)).replace("effDate", this.state.nextEffectiveDate) }}
                    />
                    :
                    this.state.reEnroll ? (
                        <p
                            style={{
                                fontSize: "14px",
                                lineHeight: "16px",
                                textAlign: "justify",
                                fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                            }}
                        >
                            Any changes will take effect on{" "}
                            <b>{this.state.nextRecurringDate}</b>. Any applicable waiting
                            periods will also begin on that date. (For details on any applicable
                            waiting periods, see the Sharing Guidelines.) If you wish to add or
                            remove new family members, click “Set up Family” above.
                        </p>
                    ) : (
                        <div>
                            {isPureCat(this.state.clientId.toString()) ? (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                        padding: "5px",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_HEADINGS.PURE_CAT")}</b>
                                    {i18n.t("PLAN_HEADINGS.COMMON_HEAD")}
                                </p>
                            ) : isWellLife(this.state.clientId.toString()) &&
                                isElite(this.state.clientId.toString()) &&
                                isAdvantage(this.state.clientId.toString()) ? (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                        padding: "5px",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_HEADINGS.WELLLIFE_ELITE_ADVANTAGE")}</b>
                                    {i18n.t("PLAN_HEADINGS.COMMON_HEAD")}
                                </p>
                            ) : isElite(this.state.clientId.toString()) &&
                                isAdvantage(this.state.clientId.toString()) ? (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_HEADINGS.ELITE_ADVANTAGE")}</b>
                                    {i18n.t("PLAN_HEADINGS.COMMON_HEAD")}
                                </p>
                            ) : isWellLife(this.state.clientId.toString()) &&
                                isElite(this.state.clientId.toString()) ? (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_HEADINGS.WELLLIFE_ELITE")}</b>
                                    {i18n.t("PLAN_HEADINGS.COMMON_HEAD")}
                                </p>
                            ) : isWellLife(this.state.clientId.toString()) &&
                                isAdvantage(this.state.clientId.toString()) ? (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                        padding: "5px",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_HEADINGS.WELLLIFE_ADVANTAGE")}</b>
                                    {i18n.t("PLAN_HEADINGS.COMMON_HEAD")}
                                </p>
                            ) : isElite(this.state.clientId.toString()) ? (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_HEADINGS.ELITE")}</b>
                                    {i18n.t("PLAN_HEADINGS.COMMON_HEAD")}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        textAlign: "justify",
                                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                                    }}
                                >
                                    <b>{i18n.t("PLAN_SELECTION.TITLE")} </b>
                                    {i18n.t("PLAN_SELECTION.TITLE2")}
                                </p>
                            )}
                        </div>
                    )}

                <div style={{ marginTop: "22px", width: "100%", display: "flex" }}>
                    {currentScreen}
                </div>

                {/* ============================= Button ============================================= */}
                {this.state.count === 0 ? (
                    <Grid
                        row
                        container
                        style={{
                            marginTop: "15px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                        xs={12}
                        sm={8}
                        md={5}
                    >
                        <Grid item style={{ marginRight: "-100px" }}>
                            <ViewButton
                                style={{ marginBottom: "10px", width: 170 }}
                                color="primary"
                                disabled={true}
                                onClick={this.showPlansModal}
                            >
                                BACK
                            </ViewButton>
                        </Grid>
                        <Grid item>
                            <ProceedButton
                                style={{ minWidth: 170 }}
                                color="primary"
                                disabled={
                                    this.state.sharingPlan === "" && this.state.targetDate === ""
                                }
                                onClick={this.submitPlan}
                            >
                                {this.props.isChangeProgram || this.props.isHouseholdUpdate
                                    ? "CONTINUE"
                                    : "Select Program"}
                            </ProceedButton>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        style={{ marginTop: "15px", display: "flex", flexDirection: "row" }}
                    >
                        <Grid item xs={8} sm={10} md={8} style={{ marginRight: "-100px" }}>
                            <ViewButton
                                style={{ marginBottom: "10px", width: 170 }}
                                color="primary"
                                onClick={this.backToSelectProgram}
                            >
                                BACK
                            </ViewButton>
                        </Grid>
                        <Grid item xs={8} sm={10} md={8}>
                            <ProceedButton
                                style={{ minWidth: 170 }}
                                color="primary"
                                disabled={
                                    this.state.sharingPlan === "" && this.state.targetDate === ""
                                }
                                onClick={this.submitPlan}
                            >
                                Proceed
                            </ProceedButton>
                        </Grid>
                    </Grid>
                )}

                {/*========================================= easy share modal ==========================*/}

                <Modal
                    size="xl"
                    show={this.state.easyShareModal}
                    onHide={(event) => {
                        this.setState({ easyShareModal: false });
                    }}
                    backdrop="static"
                >
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>netWell EasyShare</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "0px" }}>
                        {this.state.loaderShow && <Loader></Loader>}
                        <iframe
                            style={{ height: "430px", width: "100%" }}
                            onLoad={() => this.setState({ loaderShow: false })}
                            src={"https://www.netwell.com/netWell-easyshare-program-grid/"}
                        ></iframe>
                    </Modal.Body>
                    <Modal.Footer>
                        <ViewButton
                            onClick={(event) => {
                                this.setState({ easyShareModal: false });
                            }}
                        >
                            {i18n.t("BUTTON.DONE")}
                        </ViewButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xs"
                    show={this.state.msgModal}
                    onHide={(event) =>
                        this.setState({ msgModal: false, loaderShow: false, errMsg: "" })
                    }
                    backdrop="static"
                    centered
                >
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>Error Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                        }}
                    >
                        {this.state.errMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ alignItems: "right" }}>
                        <CustomeButton
                            style={{ marginTop: "10px", width: "50px", height: "40px" }}
                            onClick={() => {
                                this.setState({
                                    msgModal: false,
                                    loaderShow: false,
                                    errMsg: "",
                                });
                            }}
                        >
                            Ok
                        </CustomeButton>
                    </Modal.Footer>
                </Modal>

                {/* ---------------------------------View Program Modal----------------------------------- */}

                <Modal
                    size="xl"
                    show={this.state.modalShow}
                    onHide={(event) => this.hideModal(event, this.state.checkPrev)}
                    backdrop="static"
                >
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>{i18n.t("PLAN_SELECTION.MODEL_TITLE")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "0" }}>
                        {/* padding: '15px' ,textAlign : 'justify', overflowX : 'hidden'*/}
                        {this.state.loaderShow ? <Loader></Loader> : ""}

                        {
                            <iframe
                                style={{ height: "430px", width: "100%" }}
                                onLoad={() => this.setState({ loaderShow: false })}
                                src={this.state.iframeURL}
                            ></iframe>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomeButton
                            disabled={this.state.Checked === ""}
                            onClick={(event) =>
                                this.viewPlanHideModal(event, this.state.Checked)
                            }
                        >
                            {i18n.t("BUTTON.DONE")}
                        </CustomeButton>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        subId: state.subId,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(PlanSelection));
