import React, { Component } from "react";
import Loader from "../../../loader";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import customStyle from "../../../../Assets/CSS/stylesheet_UHS";
import Button from "@material-ui/core/Button";
import customeClasses from "../Eligibility.css";
import i18n from "../../../../i18next";
import CommonTable from "../../../CommonScreens/commonTable";
import axios from "axios";
import configurations from "../../../../configurations";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  Table,
  TableCell,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
} from "@material-ui/core";
import "../Add-ons/addOnsScreen.css";
import { isEmployer } from "../CheckClientIds";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

const CssTextField = withStyles((theme) => ({
  root: {
      "& .MuiInput-root": {
          "&:hover:not($disabled):not($focused):not($error):before": {
              borderBottom: "2px solid #533278",
          },

          "&.MuiInput-underline.Mui-focused:after": {
              borderBottom: "2px solid #533278",
          },
      },
  },
}))(TextField);

const StyledTableCell = withStyles((theme) => customStyle.tableCell)(TableCell);
const StyledTableRow = withStyles((theme) => customStyle.tableRow)(TableRow);
const ACSMCheckbox = withStyles({
  root: {
    color: "#533278",
    "&$checked": {
      color: "#533278",
    },
    "&$disabled": {
      color: "grey",
    },
  },
  checked: {},
  disabled: {
    color: "grey",
  },
})(Checkbox);

const styles = (props) => customStyle.chkEligiScreen;

// const WizardButton = withStyles(
//     customStyle.viewBtn
// )(Button);

// const ProceedButton = withStyles(
//     customStyle.proceedBtn
// )(Button);
// const ViewButton = withStyles(
//     customStyle.viewBtn
// )(Button);
const WizardButton = withStyles(customStyle.viewNetwellBtn)(Button);

const ProceedButton = withStyles(customStyle.proceedNetwellBtn)(Button);
const ViewButton = withStyles(customStyle.viewNetwellBtn)(Button);

const NextButton = withStyles(customStyle.NextButton)(Button);

class ReviewChoice extends Component {
  constructor(props) {
    super(props);
    const today = new Date().getDate() + 5;
    const tomorrow = new Date(today);

    this.state = {
      headerData: [],
      addOnsBodyData: [],
      loaderShow: true,
      progress: 0,
      count: 0,
      tableData: null,
      healthTool: false,
      membersArr: [],
      popTable: null,
      moreInfoModal: false,
      check: false,
      subID: JSON.parse(localStorage.getItem("CurrentLoginUser")).id,
      clientId: sessionStorage.getItem("CLIENT_ID"),
      minDate: null,
      reEnrollDate: null,
      reEnrollBySC: this.props.reEnrollBySC ? true : false,
      fromAgent: sessionStorage.getItem("STATE_PARAM")
        ? JSON.parse(sessionStorage.getItem("STATE_PARAM")).isAgent
          : false,
          empEffectiveDate: null,
      iframeURL: "",
      reEnroll: this.props.isChangeProgram || this.props.isHouseholdUpdate || this.props.reEnrollBySC,
      nextRecurringDate: "",
      nextEffectiveDate: "",
      tarDate: "",
      targetDate: new Date(tomorrow),
    };
  }

  componentDidMount = () => {
    sessionStorage.setItem("current_screen", "7");
    let subId = JSON.parse(localStorage.getItem("CurrentLoginUser")).id;
    axios
      .get(
        configurations.baseUrl +
          "/enrollment/saveCompletionStatus/" +
          subId +
          "/" +
          sessionStorage.getItem("current_screen"),
      )
      .then((response) => {
        if (response && response.data.code === 200)
          console.log(response.data.code);
      })
      .catch((error) => console.log(error));

    fetch(`${configurations.baseUrl}/plan/getMemberPlan/${this.props.subId}/Core`)
      .then((selectedPlan) => selectedPlan.json())
      .then((selectedPlan) => {
        let sharingPlan = "";
        let selectedPlanCode = "";
        let empEffectiveDate =
          selectedPlan.response && selectedPlan.response.effectiveDate
            ? selectedPlan.response.effectiveDate
            : null;
        this.setState({
          empEffectiveDate: empEffectiveDate,
        });
        const today = new Date();
        const tomorrow = new Date(today);
        console.log("------ date");
        let efectiveDate = new Date();
        let myNwDate = new Date();
        // let efectiveDate = empEffectiveDate ? new Date(empEffectiveDate) : new Date();
        if (
          isEmployer(sessionStorage.getItem("CLIENT_ID").toString())
        ) {
          let currentDate = today.setDate(today.getDate() + 45);
          currentDate = new Date(currentDate);
          let newCurrentDate =
            moment(currentDate).format("MM") +
            "/" +
            moment(currentDate).format("DD") +
            "/" +
            moment(currentDate).format("YYYY");
          let currEffectiveDate = new Date(empEffectiveDate);
          let newCurrEffectiveDate =
            moment(currEffectiveDate).format("MM") +
            "/" +
            moment(currEffectiveDate).format("DD") +
            "/" +
            moment(currEffectiveDate).format("YYYY");

          if (
            new Date(newCurrentDate) <
            new Date(newCurrEffectiveDate)
          ) {
            let myCurrEffDate = null;
            if (selectedPlan.response.targetDate !== null) {
              if (
                new Date(
                  moment(selectedPlan.response.targetDate).format(
                    "MM-DD-YYYY",
                  ),
                ) >
                new Date(
                  moment(currEffectiveDate).format("MM-DD-YYYY"),
                )
              ) {
                myCurrEffDate = moment
                  .utc(selectedPlan.response.targetDate)
                  .format("MM-DD-YYYY");
              } else
                myCurrEffDate = moment
                  .utc(currEffectiveDate)
                  .format("MM-DD-YYYY");
            } else
              myCurrEffDate = moment
                .utc(currEffectiveDate)
                .format("MM-DD-YYYY");
            this.setState({
              minDate: currEffectiveDate,
              myDate: myCurrEffDate,
              targetDate: myCurrEffDate,
            });
          } else {
            if (
              currentDate.getDate() === 1 ||
              currentDate.getDate() <= 15
            ) {
              currentDate.setDate(15);
              currentDate.setMonth(today.getMonth());
            } else if (currentDate.getDate() > 15) {
              currentDate.setDate(1);
              currentDate.setMonth(today.getMonth() + 1);
            }
            let myCurrDate = null;
            if (selectedPlan.response.targetDate !== null) {
              if (
                new Date(
                  moment(selectedPlan.response.targetDate).format(
                    "MM-DD-YYYY",
                  ),
                ) >
                new Date(moment(currentDate).format("MM-DD-YYYY"))
              ) {
                myCurrDate = moment
                  .utc(selectedPlan.response.targetDate)
                  .format("MM-DD-YYYY");
              } else
                myCurrDate = moment
                  .utc(currentDate)
                  .format("MM-DD-YYYY");
            } else
              myCurrDate = moment
                .utc(currentDate)
                .format("MM-DD-YYYY");
            this.setState({
              minDate: currentDate,
              myDate: myCurrDate,
              targetDate: myCurrDate,
            });
          }
        }
        if (
          !isEmployer(
            sessionStorage.getItem("CLIENT_ID").toString(),
          )
        ) {
          let myNDate = new Date();
          myNDate.setDate(myNDate.getDate() + 5);
          let newDate = null;

          if (selectedPlan.response !== null) {
            if (
              new Date(
                moment(selectedPlan.response.targetDate).format(
                  "MM-DD-YYYY",
                ),
              ) > new Date(moment(myNDate).format("MM-DD-YYYY"))
            ) {
              newDate = moment
                .utc(selectedPlan.response.targetDate)
                .format("MM-DD-YYYY");
            } else
              newDate = moment.utc(myNDate).format("MM-DD-YYYY");
          } else newDate = moment.utc(myNDate).format("MM-DD-YYYY");

          this.setState({
            minDate: new Date(myNDate),
            myDate: newDate,
            targetDate: newDate,
          });
        }

        let targetDate =
          selectedPlan.response && selectedPlan.response.targetDate
            ? moment(selectedPlan.response.targetDate).format(
              "MM-DD-YYYY",
            )
            : moment(new Date(tomorrow)).format("MM-DD-YYYY");
        this.setState({
          loaderShow: false,
          sharingPlan: sharingPlan,
          selectedPlanCode: selectedPlanCode,
          //   targetDate: targetDate,
          tarDate: targetDate,
          Checked: sharingPlan,
          checkedPlan: true,
          reEnrollDate: targetDate,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        configurations.baseUrl +
          "/addon/getReviewChoices/" +
          this.state.subID +
          "/Netwell",
      )
      .then((response) => {
        this.setState(
          {
            tableData: response.data.response[0].header,
            popTable: response.data.response[0].popData.header[0],
            loaderShow: false,
          },
          () => {
            const id = this.state.popTable.header.indexOf("Surcharge"); // 2
            const removedDrink = this.state.popTable.header.splice(id, 1);
            let header = this.state.popTable.header.forEach((e) => {
              return delete e.Surcharge;
            });
            let body = this.state.popTable.body.forEach((e) => {
              delete e.surchargeAmount;
            });
          },
        );
      });
    // .catch(error => {
    //     console.log(error);
    //     this.setState({
    //       loaderShow: false,
    //       showCommonErrorModal: true,
    //     });
    //   })
  };

  handleBack = () => {
    this.props.onClickjumpfive();
  };

  handleProceed = () => {
    this.setState({
      loaderShow: true,
    });
    let date = moment(this.state.targetDate).format("YYYY-MM-DD");
    let reEnrollDate = moment(this.state.reEnrollDate).format("YYYY-MM-DD");
    let data = {
      subId: this.state.subID,
      effectiveDate: this.state.reEnroll ? reEnrollDate : date,
    };

    axios
      .post(configurations.baseUrl + "/plan/updateMemberPlan", data)
      .then((response) => {
        this.setState({
          loaderShow: false,
        });
        this.props.onClick();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (date, didMount) => {
    console.log("date---", date);
    this.setState(
      {
        targetDate: date,
        myDate: date,
      },
      () => {
        let panel = document.getElementById("date-picker-dialog");
        panel.addEventListener("onmouseleave", function () {
          document.getElementById("date-picker-dialog-label").style.paddingTop =
            "10px";
        });
      },
    );
  };

  disableWeekends(date, clientId) {
    console.log("datdisableWeekends----", date);
    if (isEmployer(clientId.toString())) {
      if (date.getDate() === 1 || date.getDate() === 15) {
        return false;
      } else {
        return true;
      }
    }
  }

  render() {
    const { classes } = this.props;

    const today = new Date();
    const tomorrow = new Date(today);

    tomorrow.setDate(tomorrow.getDate());
    console.log("tomorrow--", tomorrow);
    let futureTomarow = new Date(today);
    console.log("futureTomarow--", futureTomarow);

    let futureDate = new Date(today);
    if (!isEmployer(sessionStorage.getItem("CLIENT_ID").toString())) {
      if (new Date().getDate() === 1 || new Date().getDate() > 1) {
        if (new Date() < new Date(this.state.empEffectiveDate)) {
          let efectiveDate = new Date(this.state.empEffectiveDate);
          let effectiveDay = new Date(this.state.empEffectiveDate).getDate();
          if (effectiveDay === 1 || effectiveDay > 1) {
            tomorrow.setDate(1);
            tomorrow.setMonth(new Date(efectiveDate).getMonth() + 1);
            futureTomarow.setDate(1);
            futureTomarow.setMonth(new Date(efectiveDate).getMonth() + 1);
          }
        } else {
          tomorrow.setDate(1);
          tomorrow.setMonth(today.getMonth() + 1);
          futureTomarow.setDate(1);
          futureTomarow.setMonth(today.getMonth() + 1);
        }
      }
      futureDate = futureTomarow.setDate(futureTomarow.getDate() + 45);
    } else if (isEmployer(this.state.clientId.toString())) {
      futureDate = new Date(this.state.minDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (futureDate.getDate() === 1) {
        futureDate.setDate(1);
        futureDate = futureDate.setDate(futureDate.getDate() + 45);
      } else {
        futureDate.setDate(15);
        futureDate = futureDate.setDate(futureDate.getDate() + 45);
      }
    }

    let currentScreen;
    currentScreen = (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Grid item xs={12} sm={12}>
            <div style={{ textAlign: "justify", width: "95%" }}>
                <p>
                  If you want to make changes to your selections, 
                  click on the "BACK" button at the bottom and select or unselect your choice on previous screens. 
                  If you're happy with your choices, click Select Program Effective Date to choose when you want your program to begin.
                </p>
            </div>
          </Grid>
          <Grid container direction="row">
            <Grid
              item
              sm={10}
              xs={12}
              style={{ marginBottom: "20px" }}
              className={
                this.props.isChangeProgram || this.props.isHouseholdUpdate
                  ? "disabledReenrollPlanSelection"
                  : ""
              }
            >
              <Grid item sm={10} xs={12} style={{ marginTop: "30px" }}>
                <div style={customStyle.EnrollNew2DateMob}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      key={
                        this.state.reEnroll
                          ? this.state.reEnrollDate
                          : this.state.myDate
                      }
                      onBlur={() => this.setState({ birthDtFocus: true })}
                      onMouseOver={() => this.setState({ birthDt: true })}
                      onMouseLeave={() => this.setState({ birthDt: false })}
                      autoComplete="off"
                      margin="none"
                      id="date-picker-dialog"
                      label="Select Program Effective Date"
                      format="MM/dd/yyyy"
                      disabled={
                        this.props.isChangeProgram || this.props.isHouseholdUpdate || this.props.reEnrollBySC
                      }
                      error={this.state.dateErr} //&&!this.state.todayDateValid
                      helperText={this.state.dateErr ? "Enter valid date" : ""} //this.state.todayDateValid?'Date Required':
                      value={
                        this.state.reEnroll
                          ? this.state.reEnrollDate
                          : this.state.myDate
                      }
                      defaultValue={
                        this.state.reEnroll
                          ? this.state.reEnrollDate
                          : this.state.myDate
                      }
                      onFocus={(e) => e.target.blur()}
                      onCopy={this.handlerCopy}
                      onPaste={this.handlerCopy}
                      inputProps={{
                        style: {
                          fontSize: "18px",
                          fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                          paddingLeft: "11px",
                          paddingRight: "10px",
                          marginTop: "11px",
                          "&:focus": { outline: "none" },
                          color: !this.state.birthDt ? "#19191d" : "#533278",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 12,
                          color: !this.state.birthDtFocus
                            ? "grey"
                            : this.state.birthDt
                              ? "#533278"
                              : "grey",
                        },
                      }} //|| !this.state.todayDateValid
                      onChange={this.handleDateChange.bind(this)}
                      variant="filled"
                      // onMouseEnter={this.handleHover}
                      TextFieldComponent={CssTextField}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ width: "100%" }}
                      shouldDisableDate={(e) =>
                        this.disableWeekends(e, this.state.clientId)
                      }
                      minDate={this.state.minDate}
                      maxDate={new Date(futureDate)}
                    />
                    <span id="bd" style={customStyle.EnrollNew2Span}></span>
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div>
            <div
              className="addOnTable reviewChoice"
              style={{ width: "100%", overflowX: "auto" }}
            >
              {this.state.tableData && (
                <CommonTable
                  quoteData={this.state.tableData.body}
                  check={true}
                  headerData={this.state.tableData.header}
                  tooltip={[]}
                  quickQuote={false}
                  totalReq={true}
                />
              )}
            </div>
            {isEmployer(this.props.clientId.toString()) ? null : (
              <p
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  paddingTop: "10px",
                }}
              >
                {/* *The total amount will include a 0% charge for the use of a credit card as your payment method. To avoid the charge, please use ACH as your payment method in the screen that follows. */}
              </p>
            )}
          </div>
        </Grid>
      </Grid>
    );

    return (
      <div style={{ flexGrow: 1 }}>
        {this.state.loaderShow ? <Loader></Loader> : ""}
        <Grid
          container
          style={{
            fontFamily: "Roboto, Arial, Helvetica, sans-serif",
            fontSize: "14px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {currentScreen}
          </Grid>
          <Grid item xs={12} sm={10} md={6} style={{ marginTop: "0px" }}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={2}>
                <WizardButton
                  disabled={false}
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={this.handleBack}
                >
                  BACK
                </WizardButton>
              </Grid>

              <Grid item xs={4} sm={3}>
                <ProceedButton
                  disabled={false}
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={this.handleProceed}
                >
                  PROCEED
                </ProceedButton>
                {/*style={{width: '104px', height: '40px'}}*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal
          size="xl"
          show={this.state.moreInfoModal}
          onHide={() => this.setState({ moreInfoModal: false })}
          backdrop="static"
          centered
        >
          <Modal.Header style={customStyle.modal_header}>
            <Modal.Title style={{ color: "white" }}>Family Details</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              fontFamily: "Roboto, Arial, Helvetica, sans-serif",
              fontSize: "14px",
              textAlign: "justify",
            }}
          >
            <div
              className={
                this.props.reEnroll
                  ? "reEnrollTable familyDetailsTable reivewTable"
                  : "reivewTable familyDetailsTable"
              }
              style={{ overflowX: "auto" }}
            >
              {/*reivewTable*/}
              {this.state.popTable && (
                <CommonTable
                  quoteData={this.state.popTable.body}
                  check={true}
                  headerData={this.state.popTable.header}
                  tooltip={[]}
                  quickQuote={false}
                  totalReq={true}
                  reEnroll={this.props.reEnroll}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ background: "#f1f1f1" }}>
            <ViewButton onClick={() => this.setState({ moreInfoModal: false })}>
              Done
            </ViewButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleAddOn() {
    if (!this.state.check) this.setState({ check: true });
    else this.setState({ check: false });
  }
}

const mapStateToProps = (state) => {
  return {
    subId: state.subId,
    email: state.email,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(ReviewChoice));
