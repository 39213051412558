import React, { Component } from 'react';
//import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Table, TableCell, Modal, TableBody, Radio, TableHead, TableContainer, TableRow, Button, Box, Tooltip, Paper, Toolbar, Typography, IconButton, Badge, Grid } from '@material-ui/core';
import Loader from '../../loader';
import { connect } from 'react-redux';
// import { } from 'react-bootstrap';
// import {Panel} from 'react-bootstrap';
import axios from "axios";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import i18n from '../../../i18next';
import customStyle from '../../../Assets/CSS/stylesheet_UHS';
import moment from "moment";
import Cart from '../../../Assets/Images/cart.svg'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import CommonDropFamily from './CommonPopup/CommonDropFamily';
import CommonDropDwn from './CommonPopup/CommonDropDwn';
import CommonTable from './CommonPopup/CommonTable'
import './Add-ons/addOnsScreen.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Modal as MyModal } from "react-bootstrap";

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    Badge: {
      // Name of the rule
      colorSecondary: {
        // Some CSS
        color: 'red',
      },
    },
  },
});

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: '#fa6446',
  },
  tooltip: {
    backgroundColor: '#fa6446',
    border: '1px solid #dadde9',
    fontSize: '12px'
  },
}));

const useStylesBootstrap_otherQuote = makeStyles(theme => ({
  arrow: {
    color: '#4a4b57',
  },
  tooltip: {
    backgroundColor: '#4a4b57',
    border: '1px solid #dadde9',
    fontSize: '12px'
  },
}));


const StyledTableRow = withStyles({
  root: {
    backgroundColor: "#F4F4F4",
    '&.Mui-selected': {
      backgroundColor: "#fdb315",
    },
    "&.Mui-selected.MuiTableRow-hover": {
      backgroundColor: "#fdb315",
    },
  },
})(TableRow);


const ProceedButton = withStyles(
  {
    root: {
      backgroundColor: '#4782c4',
      color: '#ffffff',
      fontWeight: "bold",
      '&:hover': {
        backgroundColor: '#420045',
        boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
      },
      '&:focus': {
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#BDBDBD',
        color: '#ffffff'
      },
    },
  }

)(Button);

const ViewButton = withStyles(
  // customStyle.viewBtn
  customStyle.viewNetwellBtn,
)(Button);

const styles = theme => ({
  table: {
    minWidth: 400,
  },
});

class PlanSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientid: sessionStorage.getItem("CLIENT_ID"),
      subId: JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
      loaderShow: false,
      selected: 0,
      data: null,
      listOfCost: [],
      listOfEnrollCost: [],
      familyList: [],
      ageInvalid: false,
      isDisabled: false,
      isAgeModal: false,
      primaryAbove: false,
      spouseAbove: false,
      // selectedFamily:null,
    };
  }

  getAge = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/setupfamily/getMemberInfo/${this.props.subId}`)
      .then((response) => {
        if (response && response.data.response) {
          let data = response.data.response;
          if (data.age > 64) this.state.primaryAbove = true
          else this.state.primaryAbove = false
        }
      })
    axios.get(`${process.env.REACT_APP_BASE_URL}/setupfamily/getBeneficiaryInfoBySubId/${this.props.subId}`)
      .then((response) => {
        if (response && response.data.response) {
          let data = response.data.response;
          const isAgeAbove64 = data.some(dependent => dependent.age > 64);
          if (isAgeAbove64) this.state.spouseAbove = true
          else this.state.spouseAbove = false
        }
      })
  }

  componentDidMount() {
    sessionStorage.setItem("current_screen", "5");
    let setDisabled = this.props.isChangeProgram || this.props.isHouseholdUpdate || this.props.reEnrollBySC;
    this.getAge();
    this.setState({ loaderShow: true, isDisabled: setDisabled })

    axios.get(process.env.REACT_APP_BASE_URL + "/enrollment/saveCompletionStatus/" + this.state.subId + "/" + sessionStorage.getItem("current_screen"))
      .then((response) => {
        if (response && response.data.code === 200) console.log(response.data.code)
      })
      .catch((error) => console.log(error));

    // axios.get(`https://dev.fabric.carynhealth.com/api/v33/plan/getInnovationPlanData/972391ea-4b3c-4970-835e-34d908ada4be/2001`).then((res)=>{
    axios.get(process.env.REACT_APP_BASE_URL + "/plan/getNetwellPlanData/" + `${this.state.subId}/${this.state.clientid}`).then((res) => {
      this.setState({
        // data: myData,
        data: res.data.response,
        // selectedFamily: myData[0].categoryAmountList[0].category
      }, () => {

        let listCost = this.state.listOfCost;
        this.state.data.map((res) => {
          listCost.push(res.categoryAmountList[0].amount);
        });

        let listEnrollCost = [];
        this.state.data.map((res) => {
          listEnrollCost.push(res.enrollmentFeesList[0].amount);
        });

        let listSurcharges = [];
        this.state.data.map((res) => {
          listSurcharges.push(res.enrollmentFeesList[0].surchargesAmount);
        });

        // let familyList =[];
        // this.state.data.map((res)=>{
        //     familyList.push(res.categoryAmountList[0].category);
        // })

        this.setState({ listOfCost: listCost, listOfEnrollCost: listEnrollCost, surchargesAmount: listSurcharges[0] }, () => {
          // axios.get(`https://dev.fabric.carynhealth.com/api/v33/plan/getMemberPlan/972391ea-4b3c-4970-835e-34d908ada4be/Core`).then((resp)=>{
          axios.get(`${process.env.REACT_APP_BASE_URL}/plan/getMemberPlan/${this.state.subId}/Core`).then((resp) => {

            this.setState({ loaderShow: false })
            if (resp.data.response) {
              let index = this.state.data.indexOf(this.state.data.filter(x => x.planId === resp.data.response.planId)[0]);
              let listOfCost = this.state.listOfCost;
              let listOfEnrollCost = this.state.listOfEnrollCost;
              // let family = this.state.familyList;

              let flagRemoveDepentent = this.state.data[index].categoryAmountList.find(x => x.category === resp.data.response.category) ? true : false;

              // family[index] = flagRemoveDepentent ? resp.data.response.category : this.state.data[index].categoryAmountList[0].category;
              listOfCost[index] = flagRemoveDepentent ? resp.data.response.amount : this.state.data[index].categoryAmountList[0].amount;
              if (res.data.response.find(x => x.planId === resp.data.response.planId).enrollmentFeesList.find(v => v.amount === resp.data.response.appFee)) {
                listOfEnrollCost[index] = resp.data.response.appFee;
              }
              this.setState({
                // selectedFamily: flagRemoveDepentent ? resp.data.response.category : this.state.data[index].categoryAmountList[0].category,
                selected: index,
                listOfCost: listOfCost,
                // familyList: family,
                listOfEnrollCost: listOfEnrollCost,
              })
              this.checkAgeValidation(index);
            } else this.checkAgeValidation(0);
          })
        })

      })
    })

  }


  handleClick = (index) => {
    if (!this.props.reEnrollBySC) {
      this.checkAgeValidation(index);
      this.setState({ selected: index });
    }
  };

  checkAgeValidation = async (index) => {
    let planName = this.state.data[index].planName;
      if (!planName.includes("Dental")) {
        console.log(this.state.primaryAbove, ' ', this.state.spouseAbove, );
        
        if (this.state.primaryAbove || this.state.spouseAbove) {
          this.setState({ isAgeModal: true, ageInvalid: true });
        } else {
          this.setState({ isAgeModal: false, ageInvalid: false });
        }
      } else {
        this.setState({ isAgeModal: false, ageInvalid: false });
      }
  }

  SubmitPlanRequest = (e) => {
    if (e.detail === 1) {
      let totalAmount = Number(this.state.listOfCost[this.state.selected]) + Number(this.state.surchargesAmount)
      let obj = {
        subId: this.state.subId,
        planCode: this.state.data[this.state.selected].planCode,
        planId: this.state.data[this.state.selected].planId,
        planName: this.state.data[this.state.selected].planName,
        amount: this.state.listOfCost[this.state.selected],
        appFee: this.state.listOfEnrollCost[this.state.selected],
        surchargesAmount: this.state.surchargesAmount,
        category: this.state.familyList[this.state.selected],
        planType: "Core",
      }
      // let obj = {
      //     "subId": "972391ea-4b3c-4970-835e-34d908ada4be",
      //     "planCode": "Elite MD 2",
      //     "amount": 192.9,
      //     "appFee": 125,
      //     "category": "Primary",
      //     "planType": "Core"
      // }
      this.setState({ loaderShow: true })
      axios.post(process.env.REACT_APP_BASE_URL + "/plan/saveMemberPlans", obj).then((res) => {
        // axios.post("https://dev.fabric.carynhealth.com/api/v33/plan/saveMemberPlans", obj).then((res)=>{
        if (res.data.response)
          localStorage.setItem("selectedCorePlan", obj.planName)
        this.props.onClick();
        this.setState({ loaderShow: false })
      }).catch(err => {
        console.log(err);
        this.setState({ loaderShow: false })
      })
    }
  }

  render() {
    if (this.state.data)
      return (
        <div style={{ margin: "-2%" }}>
          {this.state.loaderShow ? <Loader /> : ''}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                title={this.props.reEnrollBySC ?
                  `Your current plan ${this.state.data[this.state.selected].planId} is selected for your reference and cannot be changed.`
                  : "Review the programs available, compare fees and click the ADD button."}
                planSelected={this.state.data[this.state.selected]}
                costSelected={this.state.listOfCost[this.state.selected]}
                enrollFeeSelected={this.state.listOfEnrollCost[this.state.selected]}
                surchargeSelected={this.state.surchargesAmount}
                // selectedFamily={this.state.selectedFamily}
                reEnrollBySC={this.props.reEnrollBySC}
                isDisabled={this.state.isDisabled}
              />


              <TableContainer style={{ height: "38vh" }}>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                // size={dense ? "small" : "medium"}
                >
                  <TableBody>
                    {this.state.data.map((row, index) => {
                      const isItemSelected = index === this.state.selected;
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          hover
                          className={this.props.reEnrollBySC ? 'disableSCcss' : ""}
                          onClick={(event) => this.state.isDisabled ? event.stopPropagation() : this.handleClick(index)}
                          role="radio"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.planInfo}
                          selected={isItemSelected}>
                          <TableCell padding="checkbox" style={{ paddingTop: 0 }}>
                            <Radio
                              disabled={this.state.isDisabled}
                              checked={isItemSelected}
                              value={row.planInfo}
                              style={{
                                color: this.state.isDisabled ? "grey" : "#132244"
                              }}
                              name="radio-buttons"
                              className="radio-buttons"
                              inputProps={{ "aria-label": "A" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            Add <b style={{ color: this.state.isDisabled && "grey" }}>{row.planName}</b>
                          </TableCell>

                          {(this.props.isHouseholdUpdate || this.props.isChangeProgram || this.props.reEnrollBySC) ? (
                            null ) : (
                          <TableCell align="left" style={{ width: "10%", paddingTop: 0, paddingBottom: 0 }}>

                            {this.state.listOfEnrollCost[index] !== undefined && <CommonDropDwn
                              value={row.enrollmentFeesList}
                              index={index}
                              disabled={this.props.reEnrollBySC}
                              selectedIndex={this.state.selected === index}
                              selectedEnrollFee={this.state.listOfEnrollCost[index]}
                              selectCost={(value, ind) => {
                                let list = this.state.listOfEnrollCost;
                                list[ind] = value;
                                this.setState({ listOfEnrollCost: list })
                              }}
                            />}

                          </TableCell>
                          )}

                          {/* <TableCell align="left" style={{ width: "10%", paddingTop:0, paddingBottom: 0  }}>
                        
                        {this.state.familyList[index] && <CommonDropFamily
                          value={row.categoryAmountList}
                          index={index}
                          disabled={this.props.reEnrollBySC}
                          selectedIndex={this.state.selected === index}
                          selectedFamily={this.state.familyList[index]}
                          selectCost={(value, ind) => {
                            let list = this.state.listOfCost;
                            let familyList = this.state.familyList;
                            let amount = row.categoryAmountList.filter(
                              (x) => x.category === value
                            )[0].amount;
                            familyList[ind] = value;
                            list[ind] = amount;
                            this.setState({selectedFamily: value, listOfCost: list, familyList: familyList});
                          }}
                        />}
                      </TableCell> */}
                          <TableCell align="right" style={{ width: "10%", paddingTop: 10, paddingBottom: 10, fontFamily: "Lato", fontSize: "15px", color: "rgba(0, 0, 0, 0.6)" }}>
                            <span
                              className="cost-per-month"
                              style={{
                                fontWeight: 700,
                                fontSize: "25px",
                                color: this.state.isDisabled ? "grey" : index === this.state.selected ? "#fff" : "#91b40f"
                              }}
                            >
                              ${Number(this.state.listOfCost[index]).toFixed(2)}
                            </span>
                            /MONTH
                          </TableCell>
                          <TableCell align="right" style={{ width: "10%", paddingTop: 0, paddingBottom: 0 }}>

                            <IconButton onClick={(event) => this.state.isDisabled ? event.stopPropagation() : window.open(row.link)}>
                              <span style={{ fontSize: 13, fontWeight: "bold", color: this.state.isDisabled ? "grey" : isItemSelected ? "#132244" : "#009cde", width: 100 }}>VIEW DETAILS</span>
                            </IconButton>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

            </Paper>
          </Box>
          <div style={{ paddingTop: 40, paddingLeft: 20, paddingBottom: 20 }}>
            <ProceedButton disabled>Back</ProceedButton>
            <ProceedButton disabled={this.state.ageInvalid} style={{ marginLeft: 40 }} onClick={(e) => this.SubmitPlanRequest(e)}>Select Plan & Proceed</ProceedButton>
          </div>

          <MyModal
              size="xs"
              show={this.state.isAgeModal}
              onHide={() =>
                this.setState({
                  isAgeModal: false,
                  loaderShow: false,
                  errMsg: "",
                })
              }
              backdrop="static"
              centered
            >
              <MyModal.Header style={customStyle.modal_header} closeButton>
                <MyModal.Title>Age Eligibility Check</MyModal.Title>
              </MyModal.Header>
              <MyModal.Body
                style={{
                  margin: "10px",
                  textAlign: "center",
                  fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                }}
              >
                {"Members over 64 years of age are currently not eligible to participate in our sharing programs."}
                {" There are no age limitations on our dental programs."}
              </MyModal.Body>
              <MyModal.Footer style={{ alignItems: "right" }}>
                <ViewButton
                  style={{ marginTop: "10px", width: "50px", height: "40px" }}
                  onClick={() => {
                    this.setState({
                      isAgeModal: false,
                      loaderShow: false,
                      errMsg: "",
                    });
                  }}
                >
                  Ok
                </ViewButton>
              </MyModal.Footer>
            </MyModal>
        </div>
      )
    else return <> <Loader /> </>
  }
}

const mapStateToProps = state => {
  return {
    subId: state.subId
  };
}

export default withStyles(styles)(connect(mapStateToProps)(PlanSelection));

const EnhancedTableToolbar = (props) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Box>
        <Toolbar
          style={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            backgroundColor: "#132244",

            width: "100%",
            zIndex: 1,
          }}
        >
          <Grid row container spacing={1}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography
                style={{
                  marginTop: 15,
                  color: "#fff"
                }}
                component="div"
              >
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <IconButton>
                <h3 style={{ color: props.isDisabled ? "grey" : "#91b40f", margin: 0, fontStyle: "Lato", fontWeight: "700", marginLeft: 13 }}>
                  ${Number(Number(props.costSelected) + (props.reEnrollBySC ? 0 : Number(props.enrollFeeSelected) + Number(props.surchargeSelected))).toFixed(2)}
                </h3>
              </IconButton>
            </Grid>
            <Grid item xs={6} sm={6} md={1} lg={1}>

              <IconButton disabled={props.reEnrollBySC} onClick={(event) => props.isDisabled ? event.stopPropagation() : setModalShow(true)}>
                <img src={Cart} />
                <div style={{ marginLeft: 15, marginTop: "-7px" }}>
                  <ThemeProvider theme={theme}>
                    <Badge color="secondary" badgeContent={1} />
                  </ThemeProvider>
                </div>
              </IconButton>

            </Grid>
          </Grid>
        </Toolbar>
      </Box>
      <ModalPopup
        show={modalShow}
        // selectedFamily={props.selectedFamily}
        onHide={() => setModalShow(false)}
        planSelected={props.planSelected}
        costSelected={props.costSelected}
        enrollFeeSelected={props.enrollFeeSelected}
        surchargeSelected={props.surchargeSelected}
      />
    </>
  );
};

const ModalPopup = (props) => {
  return (
    <>
      <Modal
        open={props.show}
        onClose={props.onHide}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='ModalStyle'>
          <Box>
            {" "}
            <Toolbar
              style={{
                backgroundColor: "#458586",
                padding: 0,
                paddingLeft: 20
              }}
              className="modal-header"
            >
              <Typography
                style={{
                  flex: "1 1 100%",
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1",
                  letterSpacing: "0.15px",
                  textAlign: "left",
                  color: "#ffff",
                  padding: 0
                }}
                variant="b"
                id="tableTitle"
                component="div"
              >
                Review Choices
              </Typography>
              <IconButton onClick={props.onHide}>
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </Toolbar>
          </Box>
          <Box style={{ padding: 20, paddingBottom: 50, backgroundColor: "#fff" }}>
            <CommonTable
              planSelected={props.planSelected}
              costSelected={props.costSelected}
              // selectedFamily={props.selectedFamily}
              enrollFeeSelected={props.enrollFeeSelected}
              surchargeSelected={props.surchargeSelected}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  fontStyle: "Lato",
  height: 300,
  bgcolor: "background.paper",
  padding: 0,
  boxShadow: 24,
};